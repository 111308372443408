import React from 'react'
// import HamburgerIcon from './Hamburger'
import CloseIcon from './CloseNav'
import { CgMenu } from 'react-icons/cg'
import { IoMdClose } from 'react-icons/io'

function Icon (props) {
  switch (props.symbol) {
    case 'cross':
      return <IoMdClose />
    case 'hamburger':
      return <CgMenu />

    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
