import gsap from 'gsap'

const exitTrigger = ({ node, e, exit, entry }) => {
  console.log('node.childNodes[0].childNodes[0], ', node.childNodes[0].childNodes[0].childNodes[1])

  const hero = node.childNodes[0]
  const pageTitle = node.childNodes[0].childNodes[0].childNodes[1]
  const container = node.childNodes[1]

  const tl = gsap.timeline()
    .to(node, {
      y: 50,
      opacity: 0,
      duration: 0.5
      // scale: 1.1
    })
    // .to(hero, {
    //   height: 0,
    //   transformOrigin: 'top',
    //   duration: 0.5,
    //   ease: 'circ.in'
    // })
}

export default exitTrigger
