import gsap from 'gsap'
import scrollTrigger from 'gsap/all'
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(scrollTrigger, SplitText)

const AnimAboutSections = (section, index) => {
  const imgContainer = section.querySelector('.img-wrapper')
  const img = section.querySelector('.about-image')

  gsap.set(img, { xPercent: 100 })
  gsap.set(imgContainer, { xPercent: -100 })

  const childLines = new SplitText(section.querySelector('.about-text'), {
    type: 'lines,words',
    linesClass: 'lineChild'
  })
  const parentLines = new SplitText(section.querySelector('.about-text'), {
    type: 'lines,words',
    linesClass: 'lineParent'
  })

  gsap.set(childLines.lines, { visibility: 'hidden' })

  // Set timeout so animation occurs after Hero animation
  setTimeout(() => {
    const aboutScrollAnim = gsap.timeline({
      defaults: { ease: 'Power4.easeOut' },
      scrollTrigger: {
        id: `section-${section + 1}`,
        trigger: section,
        start: 'top 65%',
        end: 'bottom top',
        toggleActions: 'play none none reverse'
      }
    })
      .from(section, {
        yPercent: 10
      })
      .from(childLines.lines, {
        autoAlpha: 0,
        yPercent: 120,
        stagger: {
          amount: 0.35,
          each: 0.2
        },
        onComplete: () => {
          childLines.revert()
        }
      }, '<')
      .to(img, {
        xPercent: 0,
        ease: 'Power4.inOut',
        duration: 0.5
      }, '<')
      .to(imgContainer, {
        xPercent: 0,
        ease: 'Power4.inOut',
        duration: 0.5
      }, '<')
  }, 350)
}

export default AnimAboutSections
