import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

function FooterLogoImg () {
  return (
    <StaticImage
      src='../assets/images/nav-logo-trans.png'
      alt='Logo'
      placeholder='none'
      layout='fixed'
      width={75}
      quality={50}
    />
  )
}

export default FooterLogoImg
