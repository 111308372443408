import gsap from 'gsap'

const enterTrigger = ({ node, e, exit, entry }) => {
  // console.log('node = ', node, e, exit, entry)
  const hero = node.childNodes[0]
  const heroInner = node.childNodes[0].childNodes[0]
  const pageTitle = node.childNodes[0].childNodes[0].childNodes[1]

  const tl = gsap.timeline()
    .from(heroInner, {
      scale: 1.1,
      transformOrigin: 'bottom'
    })
    .from(node, {
      y: '-100',
      opacity: 0,
      duration: 0.5
    }, '<')
    // .from(hero, {
    //   height: 0,
    //   transformOrigin: 'top',
    //   duration: 0.5,
    //   ease: 'circ.out'
    // // delay: 0.5
    // }, '<')
}

export default enterTrigger
