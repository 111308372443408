
const colorBlack = '#222'
const colorWhite = '#fff'
const colorAccent = '#f43'
const colorMainBg = '#f1f1f1'
const colorSecondary = '#333'
const colorMeta = '#f6f6f7'

const gradientBg = 'linear-gradient(120deg, #c3dfdd 0%, #f7dcca 100%)'
const gradientBgInvert = 'linear-gradient(120deg, #f7dcca  0%, #c3dfdd 100%)'

export const colors = {
  colorBlack,
  colorWhite,
  colorAccent,
  colorMainBg,
  colorMeta,
  colorSecondary,
  gradientBg,
  gradientBgInvert
}
